<template>
  <blank-layout>
    <div class="sm:mx-auto sm:w-full sm:max-w-md">
      <logo class="mx-auto max-h-40 w-auto" />
    </div>

    <div class="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
      <div class="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
        <div v-if="isLoading">
          <div class="flex justify-center text-indigo-500">
            <a-icon
              name="circle-notch"
              type="fad"
              size="3x"
              spin
            />
          </div>
        </div>
        <div v-else>
          <a-alert
            v-if="isCompletedImpersonating"
            type="success"
          >
            <template #icon>
              <a-icon
                name="circle-notch"
                type="fad"
                class="text-green-500"
                size="lg"
                spin
              />
            </template>
            Impersonating is Complete. Please wait while we redirect you to the conference dashboard.
          </a-alert>
          <a-alert
            v-else
            type="error"
          >
            Sorry, It seems that the code is invalid or was already used. Impersonating Codes are valid for a single use.
          </a-alert>
        </div>
      </div>
    </div>
  </blank-layout>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import Logo from '@/components/layout/header/logo.vue';
import BlankLayout from '@/layouts/blank.vue';
export default {
  components: {
    Logo,
    BlankLayout
  },
  props: {
    uuid: {
      type: String,
      required: true
    }
  },
  data: () => ({
    isLoading: true,
    isCompletedImpersonating: false
  }),
  computed: {
    ...mapGetters('conferences', ['current'])
  },
  watch: {
    current() {
      this.onUuidChange();
    },
    uuid: {
      immediate: true,
      handler: 'onUuidChange'
    }
  },
  methods: {
    ...mapActions('impersonate', ['impersonate']),
    onComplete() {
      this.$router.push({
        name: 'dashboard',
        params: { conference: this.current }
      });
    },
    async onUuidChange() {
      if (!this.uuid || !this.current) {
        return;
      }

      try {
        await this.impersonate({
          conference: this.current,
          uuid: this.uuid
        });

        this.isCompletedImpersonating = true;

        setTimeout(() => this.onComplete(), 2000);
      } catch (e) {
        console.error(e);
      } finally {
        this.isLoading = false;
      }
    }
  }
};
</script>